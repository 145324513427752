import React, { Component } from 'react';
import axios from 'axios';
import swal from 'sweetalert';
import { API_URL } from './../../config/config';
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';
import Select from 'react-select';
//import { Switch } from 'react-router-dom';

const emailRegex = RegExp(/^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/);

const MobileRegex = RegExp(/^(\+\d{1,3}[- ]?)?\d{10}$/);

const formValid = ({ formErrors, ...rest }) => {
	let valid = true;

	// validate form errors being empty
	Object.values(formErrors).forEach((val) => {
		val.length > 0 && (valid = false);
	});

	// validate the form was filled out
	Object.values(rest).forEach((val) => {
		val === null && (valid = false);
	});

	return valid;
};

const transposrtoptions = [
	{ value: '', label: 'Transport Type' },
	{ value: 'Rail Transport', label: 'Rail Transport' },
	{ value: 'Water Transport', label: 'Water Transport' },
	{ value: 'Ground Transport', label: 'Ground Transport' },
	{ value: 'Air Transport', label: 'Air Transport' }
];
const freightoptions = [
	{ value: '', label: 'Type of freight' },
	{ value: 'Consignee Collects', label: 'Consignee Collects' },
	{ value: 'Prepay and Add', label: 'Prepay and Add' }
];
class Request_quote_module extends Component {
	constructor(props) {
		super(props);
		this.state = {
			yourName: null,
			yourEmail: null,
			yourMobile: null,
			yourPhone: null,
			transportType: null,
			typeFreight: null,
			yourMessage: null,
			formErrors: {
				yourName: '',
				yourEmail: '',
				yourMobile: '',
				yourPhone: '',
				transportType: '',
				typeFreight: '',
				yourMessage: ''
			}
		};
	}

	 componentDidMount() {
		window.scrollTo(0, 0)
	 }
	handleSubmit = (e) => {
		e.preventDefault();
		//console.log('this.state',this.state)
		if (formValid(this.state)) {
			let body = {
				webform_id: 'request_a_quote',
				your_name: this.state.yourName,
				email: this.state.yourEmail,
				mobile: this.state.yourMobile,
				phone: this.state.yourPhone,
				transport_type: this.state.transportType.value,
				type_of_freight: this.state.typeFreight.value,
				message: this.state.yourMessage
			};
			console.log('json=>', JSON.stringify(body));
			axios({
				method: 'post',
				url: API_URL + '/webform_rest/submit?_format=json',
				headers: {
					'Content-Type': 'application/json',
					Authorization: 'Basic aWJyYWluYzI6aWJyYWluYzI='
				},
				data: body
			}).then((response) => {
				let { data } = response;
				console.log('form submit responce', data.sid);
				if (data.sid !== '') {					
					swal("Thank you for getting in touch, We will get back to you shortly.")
					.then((value) => {						
						//document.getElementById("RequestQuoteFrom").reset();
						window.location.reload();
					});
				} else {
					swal('Something went wrong, please try again after sometime', '', 'error');
				}
			});
		} else {
			console.error('FORM INVALID - DISPLAY ERROR MESSAGE');
		}
	};

	// handleSubmit = (e) => {
	// 	e.preventDefault();
	// 	//console.log('this.state',this.state)
	// 	if (formValid(this.state)) {
	// 		let body = {
	// 			webform_id: 'request_a_quote',
	// 			your_name: this.state.yourName,
	// 			email: this.state.yourEmail,
	// 			mobile: this.state.yourMobile,
	// 			phone: this.state.yourPhone,
	// 			transport_type: this.state.transportType.value,
	// 			type_of_freight: this.state.typeFreight.value,
	// 			message: this.state.yourMessage
	// 		};
	// 		console.log('json=>', body);

	// 		let headers = { 'Content-Type': 'application/json' };
	// 		let url = API_URL + '/webform_rest/submit?_format=json';
	// 		let method = 'post';

	// 		// Api calling
	// 		return new Promise((resolve, reject) => {

	// 			axios
	// 				.post(API_URL + '/webform_rest/submit', { test: 'test' })
	// 				.then(function(response) {
	// 					console.log(response.data);
	// 				})
	// 				.catch(function(error) {
	// 					console.log(error);
	// 				});
	// 		});
	// 	} else {
	// 		console.error('FORM INVALID - DISPLAY ERROR MESSAGE');
	// 	}
	// };
	handleSelect = (transportType) => {
		this.setState({ transportType });
		let formErrors = { ...this.state.formErrors };
		formErrors.transportType = transportType.value === '' ? 'please select an item in the list' : '';
		this.setState({ formErrors }, () => console.log(this.state));
	};

	handleSelect2 = (typeFreight) => {
		this.setState({ typeFreight });
		let formErrors = { ...this.state.formErrors };
		formErrors.typeFreight = typeFreight.value === '' ? 'please select an item in the list' : '';
		this.setState({ formErrors }, () => console.log(this.state));
	};

	handleChange = (e) => {
		e.preventDefault();
		//this.setState({ [e.target.name]: e.target.value });
		const { name, value } = e.target;
		//console.log(e.target,'target');
		let formErrors = { ...this.state.formErrors };
		//let formErrors = this.state.formErrors;

		switch (name) {
			case 'yourName':
				formErrors.yourName = value.length < 3 ? 'minimum 3 characaters required' : '';
				break;
			case 'yourEmail':
				formErrors.yourEmail =
					value.length <= 0
						? 'email is required'
						: (formErrors.yourEmail = emailRegex.test(value) ? '' : 'invalid email address');
				break;
			case 'yourMobile':
				formErrors.yourMobile =
					value.length <= 0
						? 'mobile number required'
						: (formErrors.yourMobile = MobileRegex.test(value) ? '' : 'invalid mobile number');
				break;
			case 'yourPhone':
				formErrors.yourPhone =
					value.length <= 0
						? 'phone number required'
						: (formErrors.yourPhone = MobileRegex.test(value) ? '' : 'invalid phone number');
				break;
			case 'yourMessage':
				formErrors.yourMessage = value.length < 6 ? 'minimum 6 characaters required' : '';
				break;
			default:
				break;
		}

		this.setState({ formErrors, [name]: value }, () => console.log(this.state));
	};

	render() {
		//console.log(this.state);
		//let { yourName, yourEmail, yourMobile, yourPhone } = this.state;
		let obj = this.props.moduledata ? this.props.moduledata : null;
		if (obj !== null) {
			let banner_image = obj.field_background_banner_image ? obj.field_background_banner_image : null;
			let banner_heading = obj.field_banner_heading ? obj.field_banner_heading : null;
			//console.log('myobject', banner_image);		;
			let banner_image_url = banner_image && banner_image.url ? banner_image.url : '';
			const styles = {
				BannerImage: {
					backgroundImage: `url(${banner_image_url})`
				}
			};
			const { transportType } = this.state;
			const { typeFreight } = this.state;
			const { formErrors } = this.state;
			return (
				<div className='page_innter_container'>
					{banner_image || banner_heading ? (
						<div className='banner_section banner_inner_page'>
							<div className='banner_main_section' style={styles.BannerImage}>
								<div className='banner_content container'>
									<h2>{banner_heading.value}</h2>
								</div>
								<div className='bggrident' />
							</div>
						</div>
					) : null}
					<div className='container'>
						<div className='page_main_area request_quote_module'>
							{obj.field_request_quote_left_image && (
								<div className='d-flex justify-content-between flex-column flex-lg-row two-part-content row'>
									<div className='left-part-content image-wpr col-lg-5 col-md-12'>
										<img
											src={obj.field_request_quote_left_image.url}
											alt={obj.field_request_quote_left_image.alt}
											title={obj.field_request_quote_left_image.title}
										/>
									</div>
									<div className='right-part-content col-lg-7 col-md-12'>
										<div className='form_section'>
											{ReactHtmlParser(obj.field_request_quote_detail.value)}
											<form
												id='RequestQuoteFrom'
												className='cv_form_inner'
												onSubmit={this.handleSubmit}
												noValidate
											>
												<div className='form-group row'>
													<div className='yourName form-item col-md-6 col-12'>
														<input
															type='text'
															className={
																formErrors.yourName.length > 0 ? (
																	'form-control error '
																) : (
																	'form-control'
																)
															}
															name='yourName'
															placeholder='Your Name'
															//onChange={this.handleChange}
															onBlur={this.handleChange}
															noValidate
														/>
														{formErrors.yourName.length > 0 && (
															<span className='errorMessage'>{formErrors.yourName}</span>
														)}
													</div>
													<div className='yourEmail form-item col-md-6 col-12'>
														<input
															type='email'
															className={
																formErrors.yourEmail.length > 0 ? (
																	'form-control error '
																) : (
																	'form-control'
																)
															}
															name='yourEmail'
															placeholder='Email'
															onBlur={this.handleChange}
															noValidate
														/>
														{formErrors.yourEmail.length > 0 && (
															<span className='errorMessage'>{formErrors.yourEmail}</span>
														)}
													</div>
												</div>
												<div className='form-group row'>
													<div className='yourMobile form-item col-md-6 col-12'>
														<input
															type='text'
															className={
																formErrors.yourMobile.length > 0 ? (
																	'form-control error '
																) : (
																	'form-control'
																)
															}
															name='yourMobile'
															placeholder='Mobile'
															onBlur={this.handleChange}
															noValidate
														/>
														{formErrors.yourMobile.length > 0 && (
															<span className='errorMessage'>
																{formErrors.yourMobile}
															</span>
														)}
													</div>
													<div className='yourPhone form-item col-md-6 col-12'>
														<input
															type='text'
															className={
																formErrors.yourPhone.length > 0 ? (
																	'form-control error '
																) : (
																	'form-control'
																)
															}
															name='yourPhone'
															placeholder='Phone'
															onBlur={this.handleChange}
															noValidate
														/>
														{formErrors.yourPhone.length > 0 && (
															<span className='errorMessage'>{formErrors.yourPhone}</span>
														)}
													</div>
												</div>
												<div className='form-group row'>
													<div className='transportType form-item col-md-6 col-12'>
														<Select
															name='transportType'
															className={
																formErrors.transportType.length > 0 ? (
																	'selectbox error '
																) : (
																	'selectbox'
																)
															}
															placeholder='Transport Type'
															value={transportType}
															onChange={this.handleSelect}
															options={transposrtoptions}
															noValidate
														/>
														{formErrors.transportType.length > 0 && (
															<span className='errorMessage'>
																{formErrors.transportType}
															</span>
														)}
													</div>
													<div className='typeFreight form-item col-md-6 col-12'>
														<Select
															name='typeFreight'
															className={
																formErrors.typeFreight.length > 0 ? (
																	'selectbox error '
																) : (
																	'selectbox'
																)
															}
															placeholder='Type of freight'
															value={typeFreight}
															onChange={this.handleSelect2}
															options={freightoptions}
															noValidate
														/>
														{formErrors.typeFreight.length > 0 && (
															<span className='errorMessage'>
																{formErrors.typeFreight}
															</span>
														)}
													</div>
												</div>
												<div className='form-group row'>
													<div className='yourMessage form-item col-12'>
														<textarea
															className={
																formErrors.yourMessage.length > 0 ? (
																	'form-control error '
																) : (
																	'form-control'
																)
															}
															name='yourMessage'
															placeholder='Message'
															onBlur={this.handleChange}
															noValidate
														/>
														{formErrors.yourMessage.length > 0 && (
															<span className='errorMessage'>
																{formErrors.yourMessage}
															</span>
														)}
													</div>
												</div>

												<button type='submit' className='defaultbtn btn-yellow'>
													Request Quote
												</button>
											</form>
										</div>
									</div>
								</div>
							)}
						</div>
					</div>
				</div>
			);
		}
	}
}

export default Request_quote_module;
